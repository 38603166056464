@import './variables';
  
  
  /* add default's to weppage AKA Mark Box */
  * {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    font-size: 62.5%;
  }
  
  body {
    min-height: 100vh;
  }
  
  .box {
    width: 80%;
    margin: 0 auto;
  }
  
  .flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  
  .flex.column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  
  .flex.row-rev {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }
  
  .flex.column-rev {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  
  .flex.center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  
  .flex.start {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  
  .flex.end {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  
  .border {
    border: solid .5rem var(--primary-color);
  }
  
  h1 {
    font-size: 3.2rem;
  }
  
  h2 {
    font-size: 2.4rem;
  }
  
  h3 {
    font-size: 2.08rem;
  }
  
  h4 {
    font-size: 1.6rem;
  }
  
  h5 {
    font-size: 1.28rem;
  }
  
  h6 {
    font-size: 1.12rem;
  }
  
  p {
    font-size: 1.6rem;
  }
  
  a {
    font-size: 1.6rem;
  }
  
  li {
    font-size: 1.6rem;
  }
  
  .btn {
    display: inline-block;
    font-size: 1.6rem;
    text-align: center;
    width: auto;
    height: auto;
    padding: .2rem 1rem;
    border-radius: .7rem;
    border: .1rem solid grey;
  }
  
  .center {
    text-align: center;
  }
  
  .left {
    text-align: left;
  }
  
  .right {
    text-align: right;
  }
  
  .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: 100%;
  }
  
  .col-1 {
    width: 10%;
  }
  
  .col-2 {
    width: 20%;
  }
  
  .col-3 {
    width: 30%;
  }
  
  .col-4 {
    width: 40%;
  }
  
  .col-5 {
    width: 50%;
  }
  
  .col-6 {
    width: 60%;
  }
  
  .col-7 {
    width: 70%;
  }
  
  .col-8 {
    width: 80%;
  }
  
  .col-9 {
    width: 90%;
  }
  
  .col-10 {
    width: 100%;
  }
  
  /* end default style to webpage AKA Mark Box */
  
  /* // Extra small devices (portrait phones, less than 576px) */
  @media (min-width: 0px) {
  
    body {
      background-color: var(--primary-color);
      color: var(--text-color);
    }
    
    .col-2, .col-3, .col-5, .col-7 {
      width: 100%;
      text-align: center;
    }
    
    .col-4 {
      width: 100%;
    }
    
    
    h2 {
      font-size: 4rem;
    }
    
    h3, h4, h5 {
      text-align: center;
    }
    
    h3 {
      font-size: 3rem;
      margin: 3rem 0;
      color: var(--secondary-color);
    }
    
    h4 {
      font-size: 2.5rem;
      margin-bottom: 2rem;
      color: var(--secondary-color);
    }
    
    h5 {
      font-size: 2rem;
    }
    
    hr {
      margin: 6rem 0;
    }
    
    .title {
      font-family: var(--text-style);
      text-align: center;
      margin-bottom: 6rem;
    }
    
    p {
      text-align: center;
      color: var(--text-color);
      font-size: 1.75rem;
      margin: .5rem 0;
    }
    
    span {
      color: var(--secondary-color);
      font-size: 2rem;
    }
    
    img {
      width: 100%;
      margin: auto 0;
      margin-bottom: 2rem;
    }
    
    ul {
      list-style-type: none;
    }

    button {
      color: var(--primary-color);
      background-color: var(--text-color);
      border: var(--border-primary);
      border-radius: var(--border-radius);
      box-shadow: var(--box-shadow-primary);
      padding: var(--btn-padding);
      font-size: 1.2rem;
      font-weight: bold;
    }

    button:hover {
      cursor: pointer;
    }

  }
  
  /* // Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
  
  }
  
  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {

    .col-2 {
      width: 20%;
    }
  
  }
  
  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  
    .col-3 {
        width: 30%;
    }
  
    .col-4 {
      width: 40%;
  }
  
   
  }
  
  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    
    p {
        font-size: 2.5rem;
    }
  
    .col-5 {
        width: 45%;
        margin: 0 auto;
    }
  }