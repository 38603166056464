@media (min-width: 0px) {

    #icon-div {
        justify-content: center;
        padding: 1rem 0;
        .social-icons a {
          margin-right: 1rem;
        }
      }

}

@media (min-width: 1200px) {
    
  footer .col-10 {
    font-size: 3rem;
    } 

}