@import '../../variables';
// Small devices (landscape phones, 0 - 575px)
@media (min-width: 0px) { 
    .req-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        h2 {
            font-size: 1.2rem;
            padding: 1rem;
            text-align: center;
        }
        #route-desc {
            margin-top: 0.5rem;
            margin-bottom: 1rem;
            color: var(--primary-color);
        }
        .ned-container {
            display: flex;
            justify-content: center;
            img {
                width: unset;
            }
        }
        #execute-btn, #reset-btn {
            color: var(--text-color);
            background-color: var(--primary-color);
            border: var(--border-secondary);
            border-radius: var(--border-radius);
            box-shadow: var(--box-shadow-secondary);
            padding: var(--btn-padding);
            width: 8rem;
            font-size: 1.2rem;
            align-self: center;
            margin-bottom: 1rem;
        }
        #reset-btn {
            margin-bottom: 2rem;
        }
        textarea {
            width: 80%;
            margin: auto;
            padding: 1rem;
        }
        .roles, .customer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 80%;
            margin: auto;
            margin-top: 0.5rem;
            margin-bottom: 1rem;
            .user-role-input {
                display: flex;
                label {
                    margin-right: 0.5rem;
                }
            }
        }
        .customer {
            justify-content: center;
            .user-role-input {
                margin-right: 1rem;
            }
        }
        .error-message {
            color: red
        }
        .resp-container {
            #server-resp {
                color: var(--primary-color);
            }
            .resp {
                background-color: var(--primary-color);
                width: 80%;
                margin: auto;
                margin-bottom: 1rem;
                height: 16rem;
                overflow-y: scroll;
                #pending-req-loading {
                    color: var(--server-color);
                    margin-top: 3rem;
                }
                #server-resp {
                    margin-top: 0;
                }
                pre {
                    padding: 1rem;
                    color: var(--server-color);
                    font-weight: bold;
                }
            } 
        }
    }
}

// Small devices (landscape phones, 576 - 767px)
@media (min-width: 576px) { 

    .req-container {
        .ned-container {
            img {
                height: 40rem;
            }
        }
    }
    
}

// Medium devices (tablets, 768px - 991px)
@media (min-width: 768px) { 
    .req-container {
        h2 {
            font-size: 1.6rem;
        }
        .ned-container {
            img {
                width: unset;
            }
        }
        #execute-btn, #reset-btn {
            width: 10rem;
            font-size: 1.6rem;
        }
        textarea {
            font-size: 1.2rem;
        }
        .roles, .customer {
            label {
                font-size: 1.8rem;
            }
            .user-role-input {
                label {
                    font-size: 1.4rem;
                }
            }
        }
        .resp-container {
            .resp {
                #pending-req-loading {
                    color: var(--server-color);
                    margin-top: 3rem;
                    svg {
                        height: 10rem;
                        width: 10rem;
                    }
                }
                pre {
                    font-size: 1.2rem;
                }
            } 
        }
    }
}

// Large devices (desktops, 992px and below)
@media (min-width: 992px) { 
    
}

// Extra large devices (large desktops, 1200px and below)
@media (min-width: 1200px) { 

}

// Extra large devices (large desktops, 1600px and below)
@media (min-width: 1600px) { 

}