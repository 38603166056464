@media (min-width: 0px) {

    #resume-link-div {
        justify-content: center;
      }
      
      #resume-link {
        text-decoration: none;
        color: var(--text-color);
        margin-bottom: 3rem;
        font-size: 2rem;
      }
      
      #skills-div {
        justify-content: center;
      } 
      
      .skill {
        text-decoration: none;
        font-size: 2rem;
        margin-bottom: .5rem;
      }

}