@media (min-width: 0px) {

    #bio {
        margin: 3rem 0;
      }

      #me-img {
        height: 276px;
        width: 180px;
      }

}