@media (min-width: 0px) {

    .error-text {
        color: var(--secondary-color);
        font-size: 3rem;
      }

      .form {
        flex-direction: column;
        font-size: 4rem;
        margin-bottom: 1rem;
      }
      
      #form-btn-div {
        justify-content: center;
      }
      
      #form-btn {
        font-size: 2rem;
        align-items: center;
        width: 12.8rem;
      }

      #email-link {
        font-size: 2.5rem;
        text-decoration: none;
      }
}

@media (min-width: 768px) {

    #contact-form {
        width: 61.5rem;
        height: 35.4rem;
        margin: 0 auto;
      }

}